
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "email-validation",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let email_otp_form = ref("");

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const emailValidation = Yup.object().shape({
      email_otp: Yup.string().required().min(6).max(6).label("Otp"),
    });

    onMounted(() => {
      if(route.params.email_otp) {
        email_otp_form.value = route.params.email_otp as any
        onSubmitemailValidation({ email_otp: route.params.email_otp});
      }
    })

    //Form submit function
    const onSubmitemailValidation = async (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      // Send email validation request
      const email_verify_params = {
        email: route.params.email,
        otp: values.email_otp,
      };
      await store.dispatch(Actions.VERIFY_EMAIL_OTP, email_verify_params);

      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];

      if (!error) {
        Swal.fire({
          text: "Email Validation successfull. You have successfully logged in!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          router.push({ name: "mrbSearch-submitReport" });
        });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
    };

    return {
      onSubmitemailValidation,
      emailValidation,
      submitButton,
      email_otp_form,
    };
  },
});
